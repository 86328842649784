@import '../../styles/customMediaQueries.css';

.root {
  display: block;

  flex-shrink: 0;
  padding: 0 24px;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.logo {
  /* If height is increased, change link margins accordingly */
  /* NOTE: extremely wide logos may look bad. This will scale them to container of 24 x 200. */
  height: 24px;
  width: auto;
  max-width: 188px;
  object-fit: contain;
  object-position: center;
  margin: 18px 0;
  transform: scale(2);
  padding: 0 1rem;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}
